import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ICompanyInfo } from '@core/models/app.model';
import { ICounterpartyOnboardingInfo, IManagedCompanyContact } from '@core/models/onboarding.model';
import { BRSharedFiles, PolicyFilesBean } from '@core/models/policy-file.model';
import {
  CounterpartyScore,
  ISubmittedQuestionnaireWithScoring,
} from '@core/models/questionnaire.model';
import { IFollowupQuestion, InternalNote } from '@core/models/text-message.model';
import { Utils } from '@core/models/utils';
import { PermissionService } from '@core/permissions/permission.service';
import { getParamFromRouteOrParent } from '@helpers/get-param-from-route-or-parent';
import { brUrls } from '@shared/domain/ume-br-urls';
import { networkUrls } from '@shared/domain/ume-network-urls';
import { IDDQBanner } from '@standalone/banners/models/banners.model';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  getCompanyStoreValue,
  setBrId,
  setControlledStatus,
} from 'src/app/routes/company/modules/company-details/repository/company.repository';

import { setSelectedDistributor } from '../../routes/company/modules/company-details/repository/company.repository';
import { ScoringMediatorService } from '../../routes/company/services/scoring-mediator.service';
import { VirtualPremiumService } from './virtual-premium.service';

@Injectable({
  providedIn: 'root',
})
export class BRService {
  constructor(
    private http: HttpClient,
    private scoringMediator: ScoringMediatorService
  ) {}

  getCompanyContactsBr(brId: string): Observable<IManagedCompanyContact[]> {
    return this.http.get<IManagedCompanyContact[]>(networkUrls.ALL_MANAGED_CONTACTS(brId));
  }

  getCompanyInternalNotes(brId: string): Observable<InternalNote[]> {
    return this.http.get<InternalNote[]>(networkUrls.ALL_INTERNAL_NOTES(brId.toString()));
  }

  getFollowupQuestions(brId: string): Observable<IFollowupQuestion[]> {
    return this.http.get<IFollowupQuestion[]>(brUrls.BRS_FOLLOWUP_QUESTIONS(brId));
  }

  getDistributionChain(brId: string): Observable<ICompanyInfo[]> {
    return this.http.get<ICompanyInfo[]>(brUrls.BRS_PATH(brId)).pipe(
      tap((chain) => {
        setSelectedDistributor(chain[chain.length - 1]);
      })
    );
  }

  viewSubmittedQuestionnaire(
    brId: string,
    params?: Params
  ): Observable<ISubmittedQuestionnaireWithScoring> {
    return this.http
      .get<ISubmittedQuestionnaireWithScoring>(brUrls.BRS_QUESTIONNAIRE(brId), {
        params: params as Params,
      })
      .pipe(tap((scoring) => this.scoringMediator.setQuestionnaireStatus(scoring.validityStatus)));
  }

  getQuestionnaireBannerInfo(brId: string): Observable<IDDQBanner> {
    return this.http.get<IDDQBanner>(brUrls.BRS_QUESTIONNAIRE_BANNER(brId));
  }

  getScoring(brId: string, containerId?: string): Observable<CounterpartyScore> {
    let params = new HttpParams();
    if (containerId) {
      params = params.set('containerId', containerId.toString());
    }
    return this.http
      .get<CounterpartyScore>(brUrls.BRS_SCORE_CURRENT(brId), { params })
      .pipe(tap((scoring) => this.scoringMediator.setScore(scoring)));
  }

  getBrPolicyFiles(brId: string): Observable<PolicyFilesBean> {
    return this.http.get<PolicyFilesBean>(brUrls.BR_POLICY_FILES(brId));
  }

  getSharedFiles(brId: string, companyId?: string): Observable<BRSharedFiles | null> {
    let params = new HttpParams();
    if (companyId) {
      params = params.set('companyId', companyId.toString());
    }
    return this.http.get<BRSharedFiles | null>(brUrls.NEW_SHARED_FILES(brId), {
      params,
    });
  }

  /**
   * returns status of distributor onboarding request
   * @param brId
   */
  getControlledStatus(brId: string): Observable<ICounterpartyOnboardingInfo> {
    return this.http.get<ICounterpartyOnboardingInfo>(brUrls.BRS_CONTROLLED_STATUS(brId)).pipe(
      tap((onboardingInfoBean: ICounterpartyOnboardingInfo) => {
        setControlledStatus(onboardingInfoBean);
      })
    );
  }

  changeVersion(brId: string, guid: string): Observable<CounterpartyScore> {
    const params = new HttpParams().set('id', guid.toString());
    return this.http.get<CounterpartyScore>(brUrls.BRS_ROLLBACK_TO_DDQ(brId), {
      params,
    });
  }
}

export const brGuIdResolver: ResolveFn<string | null> = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const brId = Utils.parentRouteParam(state.root, 'brId');
  brId ? setBrId(brId) : of(brId);
  return of(brId);
};

export const brCompanyContactsResolver: ResolveFn<null | IManagedCompanyContact[]> = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const brId = Utils.parentRouteParam(state.root, 'brId');
  const isVirtualPremiumClient: boolean = inject(PermissionService).isVirtualPremiumClient();

  return brId
    ? isVirtualPremiumClient
      ? inject(VirtualPremiumService).getContactsForVPCompany(brId)
      : inject(BRService).getCompanyContactsBr(brId)
    : of(null);
};

export const brDistributionChainResolver: ResolveFn<ICompanyInfo[]> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const brId = route.params['brId'];
  return inject(BRService).getDistributionChain(brId);
};

export const brSubmittedQuestionnaireResolver: ResolveFn<
  ISubmittedQuestionnaireWithScoring | null
> = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const brId = getParamFromRouteOrParent(route, 'brId');
  return brId ? inject(BRService).viewSubmittedQuestionnaire(brId) : of(null);
};

export const brDDQBannerResolver: ResolveFn<IDDQBanner | null> = (
  route: ActivatedRouteSnapshot
) => {
  const brId = getParamFromRouteOrParent(route, 'brId');
  return brId
    ? inject(BRService)
        .getQuestionnaireBannerInfo(brId)
        .pipe(catchError(() => of(null)))
    : of(null);
};

export const brScoreResolver: ResolveFn<CounterpartyScore> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const brId = getParamFromRouteOrParent(route, 'brId');
  if (!brId) {
    throw new Error('BRScoreResolver: Could not get brId from route params');
  }

  return inject(BRService).getScoring(brId);
};

export const controlledStatusResolver: ResolveFn<ICounterpartyOnboardingInfo> = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const brId = Utils.parentRouteParam(state.root, 'brId');
  const status: ICounterpartyOnboardingInfo | null = getCompanyStoreValue().controlledStatus;

  return status && Object.keys(status).length > 0
    ? of(status)
    : brId
      ? inject(BRService).getControlledStatus(brId)
      : EMPTY;
};
